var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home"},[_c('v-carousel',{attrs:{"hide-delimiters":"","height":"500","show-arrows":_vm.slides.length > 1},scopedSlots:_vm._u([{key:"prev",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"slide-icon slide-icon-left",attrs:{"size":"30"}},'v-icon',attrs,false),on),[_vm._v("\n        mdi-chevron-left\n      ")])]}},{key:"next",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"slide-icon slide-icon-right",attrs:{"size":"30"}},'v-icon',attrs,false),on),[_vm._v("\n        mdi-chevron-right\n      ")])]}}])},[_vm._v(" "),_vm._v(" "),_vm._l((_vm.slides),function(item,i){return _c('v-carousel-item',{key:i,staticClass:"slide-img",attrs:{"src":item.imageUrl}},[(!item.videoUrl)?_c('div',{class:['slide-text-container ', (item.contentPosition ? item.contentPosition : 'left')]},[_c('div',{staticClass:"slide-text"},[_vm._v(_vm._s(item.text))]),_vm._v(" "),_c('nuxt-link',{staticClass:"slide-button",attrs:{"to":_vm.localePath("/product-list")}},[_vm._v("\n          "+_vm._s(item.buttonText)+"\n      ")])],1):_c('video',{staticClass:"slide-video",attrs:{"width":"100%","height":"100%","preload":"auto","autoplay":"","muted":"","loop":"","oncontextmenu":"return false"},domProps:{"muted":true},on:{"click":function($event){return _vm.redirectTo(item.videoRedirectUrl)}}},[_c('source',{attrs:{"src":item.videoUrl}})])])})],2),_vm._v(" "),_c('collection-list'),_vm._v(" "),_c('div',{staticClass:"discover2",style:({
      backgroundImage:
        'url(' + _vm.section.img_secondpicture.fieldValue.fullFileUrl + ')',
    })},[_c('div',{staticClass:"discover2-text discover2-align"},[_vm._v("Our Story")]),_vm._v(" "),_c('nuxt-link',{staticClass:"slide-button btn-big discover2-btn discover2-align",attrs:{"to":_vm.localePath("/our-story")}},[_vm._v("\n      Learn More\n    ")])],1),_vm._v(" "),_c('fieldset',{staticClass:"commitment-section"},[_c('legend',{staticClass:"title fst-italic"},[_vm._v(_vm._s(_vm.section.commitment_title.fieldValue.value))]),_vm._v(" "),_c('div',{staticClass:"list"},_vm._l((_vm.section.commitment_list),function(item){return _c('div',{staticClass:"col col-lg-3 col-md-6"},[_c('div',{staticClass:"item"},[_c('v-img',{staticClass:"img",attrs:{"src":item.image.fieldValue.fullFileUrl}}),_vm._v(" "),_c('div',{staticClass:"item-title"},[_vm._v(_vm._s(item.title.fieldValue.value))]),_vm._v(" "),_c('div',{staticClass:"text"},[_vm._v(_vm._s(item.text.fieldValue.value))]),_vm._v(" "),_c('div',{staticClass:"link"},[_c('nuxt-link',{attrs:{"to":_vm.localePath("/"+item.link_url.fieldValue.value)}},[_vm._v(_vm._s(item.link_title.fieldValue.value)+" >")])],1)],1)])}),0)]),_vm._v(" "),_c('div',{staticClass:"instagram-text"},[_vm._v("\n    #shareyourVlora\n  ")]),_vm._v(" "),_c('div',{staticClass:"elfsight-app-2464ba5f-c861-4a56-903d-387153c967c3"}),_vm._v(" "),_c('div',{staticClass:"social-media"},[_c('a',{staticClass:"social-logo",attrs:{"href":"https://www.facebook.com/vlorajewelry/"}},[_c('v-img',{staticClass:"social-logo-img",attrs:{"src":require("~/frontend/assets/images/Vdora-FacebookIcon.png")}})],1),_vm._v(" "),_c('a',{staticClass:"social-logo",attrs:{"href":"https://www.instagram.com/vlorajewelry/"}},[_c('v-img',{staticClass:"social-logo-img",attrs:{"src":require("~/frontend/assets/images/Vdora-InstagramIcon_v.png")}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }