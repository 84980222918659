//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'CollectionsList',
  props: {},
  data: () => {
    return {
      categories: [],
      start: 0,
      end: 0,

    }
  },
  mounted() {
    this.requestSubCategories();
    if(window.innerWidth > 1300){
      this.end = 2
    }
  },
  methods: {
    async requestSubCategories() {
      await this.$axios
          .$get(
              'productcategories/categorieswithchild/ShopCollection'
          ).then(async (result) => {
            if (result.hasErrors) {
              this.$toast.error(
                  result.errorMessage
              )
            } else {
              this.categories = result.value
            }

          })
      window.addEventListener('resize', this.getDefaultStartAndEndIndex);
    },
    getDefaultStartAndEndIndex(e) {
      if (e && e.target) {
        if (e.target.innerWidth > 1300) {
          this.start = 0;
          this.end = 2;
        } else {
          this.start = 0;
          this.end = 0;
        }
      }
    },
    getCollections(start, end) {
      if (start <= end) {
        return this.categories.slice(start, end + 1)
      } else {
        const length = this.categories.length
        return [...this.categories.slice(start, length), ...this.categories.slice(0, end + 1)]
      }
    },
    next() {
      if ((this.end - this.start) > 0 &&
          this.end < (this.categories.length - 1) &&
          this.start < (this.categories.length - 1)) {
        this.start++
        this.end++
      } else {
        this.end = this.end === (this.categories.length - 1) ? 0 : this.end + 1
        this.start = this.start === (this.categories.length - 1) ? 0 : this.start + 1
      }
    },
    previous() {
      if ((this.end - this.start) > 0 &&
          this.start > 0) {
        this.start--
        this.end--
      } else {
        this.start = this.start === 0 ? this.categories.length - 1 : this.start - 1
        this.end = this.end === 0 ? this.categories.length - 1 : this.end - 1
      }
    },
  }
}

