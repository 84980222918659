//
//
//
//
//
//
//
//
//
//
//
//

import CollectionCard from '@/frontend/components/common/collection-card'

export default {
  name: 'Collections',
  components:{
    CollectionCard,
  },
  props: {
    
  },
  data: () => {
    return {
      categories: [],
      index:0,
      category:{
        name:'',
        description:'',
        imageURL:'',
      }
    }
  },
  mounted() {
    this.requestSubCategories();
  },
  methods: {
   async  requestSubCategories() {
      await this.$axios
          .$get(
              'productcategories/categorieswithchild/ShopCollection'
          ).then((result) => {
           // this.categories=categories.value
            if(result.hasErrors) {
              this.$toast.error(
                  result.errorMessage
              )
            }
            else {
              this.categories=result.value
              const [first]=this.categories
              this.setCategory(first)
            }
          })
    },
    setCategory(category){
     if(!category)
     {
       return
     }
     this.category.name=category.name
      this.category.description=category.description
      this.category.imageURL=category.imageURL
    },
    next() {
      this.index = (this.categories && this.index === (this.categories.length - 1)) ? 0 : this.index + 1
      const [category] = this.categories.filter((cat, ind) => ind === this.index)
      if(category){
        this.setCategory(category)
      }
    },
    previous() {
     this.index = this.index === 0 ? (this.categories.length - 1) : this.index - 1
      const [category] = this.categories.filter((cat, ind) => ind === this.index)
      if(category){
        this.setCategory(category)
      }
    },

  }
}
