//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "collection-card",
  props:{
    category:{
      type: Object,
    },
    hasPreviousCollection:{ 
      type: Boolean,
    },
    hasNextCollection:{ 
      type: Boolean,
    },
    leftImage:{
      type: Boolean,
      default: false,
    },
  },
  methods:{
    previous(e){
      this.$emit('previous', e)
    },
    next(e){
      this.$emit('next', e)
    }
  },
}
