//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Collection from '@/frontend/components/common/collection'
import CollectionList from '@/frontend/components/common/collection-list'
export default {
  name: 'Home',
  components: {
    Collection,
    CollectionList,
  },
  props: {
    section: {
      type: Object,
      required: true,
    },
  },
  data: () => {
    return {
      slides: [],
    }
  },
  mounted() {
    this.setUpSlide()
  },
  methods: {
    setUpSlide() {
      this.slides = this.section.images.map((item) => {
        return {
          imageUrl: item.picture.fieldValue.fullFileUrl,
          videoUrl: item.video.fieldValue.fullFileUrl,
          videoRedirectUrl: item.video_redirect_link.fieldValue.value,
          buttonText: item.buttontext.fieldValue.value,
          text: item.text.fieldValue.value,
          contentPosition: item.contentposition.fieldValue.value
      }
      })
    },
    redirectTo(url) {
      if(url){
        this.$router.push(url)
      }
    },
  },
}
